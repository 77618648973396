import ErrorMessage from "../../components/shared/ErrorMessage.react.js";

interface Props {
  errors?: string[];
  notice?: string;
  noticeType: string;
  noLabelKeys?: string[];
  model?: string;
  className?: string;
}

const Notice: React.FC<Props> = ({ errors, notice, noticeType, noLabelKeys, model, className }) => {
  if (!notice && !errors) return null;

  const classes = `alert alert-${noticeType}`;

  return <div className={ className || "mt-50" }>
    <ErrorMessage errors={errors} noLabelKeys={noLabelKeys} model={model} />
    {
      notice?.length > 0 &&
      <div className={ classes }>
        {notice}
      </div>
    }
  </div>;
};

export default Notice;
