import { Component } from "react";
import PropTypes from "prop-types";

const NOT_FOUND = "not found";

class ErrorMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: true };
  }

  componentDidMount() {
    const { timeout } = this.props;
    if (timeout) {
      this.timeoutId = setTimeout(() => {
        this.setState({ visible: false });
      }, timeout);
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { errors, additionalClasses, errorType, errorsHeaderMessage } = this.props;
    const { visible } = this.state;

    if (!errors || !visible) {
      return null;
    }

    const errorMessages = this.extractErrorMessages(errors);

    if (errorMessages.length === 0) {
      return null;
    }

    return <dl className={`alert alert-${errorType} ${additionalClasses || ""}`}>
      {errorsHeaderMessage ? <strong>{errorsHeaderMessage} :</strong> : null}
      {errorMessages}
    </dl>;
  }

  extractErrorMessages(errors) {
    return Object.entries(errors).reduce((acc, [errorKey, errorMessages]) => {
      if (Array.isArray(errorMessages)) {
        acc.push(...this.extractErrorMessagesForKey(errorKey, errorMessages));
      } else if (typeof errorMessages === "string") {
        acc.push(errorMessages);
      } else { // errors inside object relations
        Object.values(errorMessages).forEach((subObjectWithErrors) => {
          acc.push(...this.extractErrorMessages(subObjectWithErrors));
        });
      }
      return acc;
    }, []);
  }

  extractErrorMessagesForKey(key, errors) {
    if (errors.length === 0) {
      return [];
    }

    const { noLabelKeys } = this.props;
    let messages = [];

    if (!noLabelKeys.includes(key)) {
      messages.push(<dt key={key}>{this.humanizeKey(key)}</dt>);
    }
    messages.push(...this.errorMessagesForKey(key, errors));

    return messages;
  }

  errorMessagesForKey(key, messages) {
    switch (typeof messages) {
    case "string":
      return [<dd key={key}>{messages}</dd>];
    case "object":
      return messages.map((m, i) => <dd key={`${key}${i}`}>{m}</dd>);
    default:
      return [];
    }
  }

  humanizeKey(key) {
    const { model, i18nCustomPath } = this.props;

    if (key === "base" || key === "error") {
      return I18n.t("error");
    }
    if (!model && !i18nCustomPath) {
      return key;
    }
    const translation = i18nCustomPath ?
      I18n.t(`${i18nCustomPath}.${key}`, { defaultValue: NOT_FOUND }) :
      I18n.t(`mongoid.attributes.${model}.${key}`, { defaultValue: NOT_FOUND });

    if (translation === NOT_FOUND) {
      // missing translation for this key, just return the raw key
      return key;
    }
    return translation;
  }
}

ErrorMessage.propTypes = {
  errors: PropTypes.object,
  noLabelKeys: PropTypes.arrayOf(PropTypes.string),
  model: PropTypes.string,
  errorType: PropTypes.string,
  errorsHeaderMessage: PropTypes.string,
  i18nCustomPath: PropTypes.string,
  timeout: PropTypes.number
};

ErrorMessage.defaultProps = {
  errors: {},
  noLabelKeys: [],
  errorType: "danger",
  errorsHeaderMessage: null,
  timeout: null
};

export default ErrorMessage;
