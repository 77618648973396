import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { LeadQualificationField } from "../../types/LeadQualificationField";
import LeadQualificationFieldEdit from "./LeadQualificationFieldEdit.react";
import LeadQualificationFieldShow from "./LeadQualificationFieldShow.react";
import ErrorMessage from "../shared/ErrorMessage.react";
import Notice from "../features/Notice.react";
import { DragTypes } from "../../constants/Constants";
import Sortable from "../Sortable.react";
import $ from "jquery";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`front_office.react.lead_qualification_fields.${key}`, options);
};

interface Props {
  leadQualificationFields: LeadQualificationField[];
  errors?: string[];
  notice?: string;
  onChange(leadQualificationFields: LeadQualificationField[]): void;
  onSave(leadQualificationFields: LeadQualificationField[]): void;
}

const LeadQualificationFields: React.FC<Props> = (
  {
    leadQualificationFields,
    errors,
    notice,
    onChange,
    onSave
  }) => {

  const [focusedElementIndex, setFocusedElementIndex] = useState<number>(0);

  useEffect(() => {
    if (errors || notice) $(".full-height-form").animate({ scrollTop: 0 }, 500, "swing");
  }, [errors, notice]);

  const onSaveQualificationFields = (): void => {
    if (onSave) {
      onSave(leadQualificationFields);
    }
  };

  const addQualificationField = (type = "text"): (() => void) => {
    return () => {
      const newField = { label: i18n("new_field_label"), type, rank: 1000 + (leadQualificationFields?.length || 0) * 1000 } as LeadQualificationField;
      if (type === "value_list") newField.possible_values = ["Option 1"];
      const newFields = [...leadQualificationFields, newField];
      onChange(newFields);
      setFocusedElementIndex(newFields.length - 1);
    };
  };

  const onChangeField = (fieldIndex: number): ((leadQualificationField: LeadQualificationField) => void) => {
    return (leadQualificationField: LeadQualificationField): void => {
      const newFields = [...leadQualificationFields];
      newFields[fieldIndex] = leadQualificationField;
      onChange(newFields);
    };
  };

  const onDestroyField = (fieldIndex: number): (() => void) => {
    return (): void => {
      const newFields = [...leadQualificationFields];
      newFields[fieldIndex].hidden = true;
      onChange(newFields);
    };
  };

  const sortableFields = leadQualificationFields?.map((field, index) => {
    return { ...field, index, rank: field.rank || (index + 1) * 1000 };
  });

  const handleFieldDrop = (_a: any, _b: any, _c: any, _d: any, sortedItems: any): void => {
    const newLeadQualificationFields = sortedItems.map((item, index) => {
      const field = item.item as LeadQualificationField;
      field.rank = (index + 1) * 1000;
      return field;
    });
    onChange(newLeadQualificationFields);
  };

  const onFocusElement = (index: number): (() => void) => {
    return (): void => {
      setFocusedElementIndex(index);
      setTimeout(() => {
        document.getElementById(`fields-form-builder-list-element-${index}`)?.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 100);
    };
  };

  const renderSortableFieldsList = (): JSX.Element => {
    const fields = sortableFields?.map((field, index) => {
      const style = field.hidden ? { display: "none" } : {};

      return (
        <div key={index} style={style} className={`list-group-item ${field.hidden ? "hidden" : ""} ${index === focusedElementIndex ? "item-focused" : ""}`}>
          <span><i className="fa-solid fa-grip-dots-vertical mr-5"></i></span>
          <span className="title-item" onClick={onFocusElement(index)}>
            {field.label}
          </span>
          <a href="#" className="float-end btn btn-link" onClick={onDestroyField(index)}>
            <i className="fa-regular fa-trash-can"></i>
          </a>
        </div>
      );
    });

    return <div className="sidebar-body">
      <div className="card">
        <div className="list-group">
          <Sortable itemIdKey="index"
            itemIndexKey="rank"
            dragType={DragTypes.LEAD_QUALIFICATION_FIELDS}
            items={sortableFields}
            handleDrop={handleFieldDrop}
            fullyDraggable={true}
          >
            { fields }
          </Sortable>
        </div>
      </div>
    </div>;
  };

  const renderNoItems = (): JSX.Element => {
    return (
      <div className="sidebar-body">
        <p>{I18n.t("react.registration_form.no_item_dnd")}</p>
      </div>);
  };

  const renderLeadQualificationFields = (): JSX.Element[] => {
    return leadQualificationFields.map((leadQualificationField, index) => {
      const style = leadQualificationField.hidden ? { display: "none" } : {};

      if (index === focusedElementIndex) {
        return <div style={style} key={index} id={`fields-form-builder-list-element-${index}`}>
          <LeadQualificationFieldEdit
            leadQualificationField={leadQualificationField}
            onChange={onChangeField(index)}
          />
        </div>;
      } else {
        return <div style={style} key={index} onClick={(): void => setFocusedElementIndex(index)} id={`fields-form-builder-list-element-${index}`}>
          <LeadQualificationFieldShow
            leadQualificationField={leadQualificationField}
          />
        </div>;
      }
    });
  };

  const renderAddFieldButton = (): JSX.Element => {
    return <>
      <Dropdown placement="bottom">
        <Dropdown.Toggle><i className="fa-regular fa-plus"></i> <span className="d-none d-md-inline">{ i18n("add_field") }</span></Dropdown.Toggle>
        <Dropdown.Menu>
          <a href="#" className="dropdown-item" onClick={addQualificationField("text")}><i className="fa-regular fa-text fa-fw"></i> { i18n("type_text") }</a>
          <a href="#" className="dropdown-item" onClick={addQualificationField("value_list")}><i className="fa-regular fa-square-caret-down form-item fa-fw"></i>{ i18n("type_value_list") }</a>
          <a href="#" className="dropdown-item" onClick={addQualificationField("rating")}><i className="fa-regular fa-star fa-fw"></i> { i18n("type_rating") }</a>
        </Dropdown.Menu>
      </Dropdown>
    </>;
  };

  const renderDNDHelp = (): JSX.Element => {
    return <span className="fields-form-builder-sidebar-help"><i className="fa-regular fa-hand"></i> { i18n("dnd_help") }</span>;
  };

  const renderSaveButton = (): JSX.Element => {
    return <button className="btn btn-primary" onClick={onSaveQualificationFields}>{ i18n("save") }</button>;
  };

  const renderAlert = (): JSX.Element => {
    return <>
      <ErrorMessage errors={errors} noLabelKeys={["lead_qualification_fields"]} additionalClasses="mt-10"/>
      <Notice notice={notice} noticeType="success" className="mt-10"/>
    </>;
  };

  return <div className="container-fluid" style={{ height: "100%" }}>
    <div className="row" style={{ height: "100%" }}>
      <div className="col-3 fields-form-builder-sidebar">
        <div className="fields-form-builder-sidebar-card">
          <div className="sidebar-header display-flex fd-col justify-content-between">
            { renderAddFieldButton() }
            { renderDNDHelp() }
          </div>
          {sortableFields.length > 0 ? renderSortableFieldsList() : renderNoItems()}
          <div className="card-footer">
            { renderSaveButton() }
          </div>
        </div>
      </div>
      <div className="col-9 full-height-form">
        { renderAlert() }
        { renderLeadQualificationFields() }
      </div>
    </div>
  </div>;
};

export default LeadQualificationFields;
