import { LeadQualificationField } from "../../types/LeadQualificationField";

interface Props {
  leadQualificationField: LeadQualificationField;
}

const LeadQualificationFieldShow: React.FC<Props> = ({ leadQualificationField }) => {
  const renderPossibleValueOptions = (): JSX.Element[] => {
    return leadQualificationField.possible_values?.map((possibleValue, index) => {
      return <option key={index}>{ possibleValue }</option>;
    });
  };

  const renderPossibleValues = (): JSX.Element => {
    return <div className="row">
      <div className="col-sm-7">
        <select className="form-select" disabled aria-readonly="true">
          { renderPossibleValueOptions() }
        </select>
      </div>
    </div>;
  };

  const renderTextInput = (): JSX.Element => {
    return <div className="mb-3 row">
      <div className="col-sm-7">
        <input type="text" className="form-control" disabled readOnly />
      </div>
    </div>;
  };

  const renderRatingInput = (): JSX.Element => {
    return <div className="rating d-flex align-items-center form-text">
      <i className="fa-solid fa-star" aria-hidden="true"></i>
      <i className="fa-solid fa-star" aria-hidden="true"></i>
      <i className="fa-solid fa-star" aria-hidden="true"></i>
      <i className="fa-solid fa-star" aria-hidden="true"></i>
      <i className="fa-solid fa-star" aria-hidden="true"></i>
    </div>;
  };

  const renderQuestion = (): JSX.Element => {
    if (leadQualificationField.type === "text") return renderTextInput();
    if (leadQualificationField.type === "rating") return renderRatingInput();
    if (leadQualificationField.type === "value_list") return renderPossibleValues();
  };

  return <div className="card">
    <div className="card-body">
      <label className="form-label text-muted">{ leadQualificationField.label}</label>
      { renderQuestion() }
    </div>
  </div>;
};

export default LeadQualificationFieldShow;
