import { Children, cloneElement, Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { DragDropContext, DragSource, DropTarget, DragLayer } from "react-dnd";
import HTML5Backend, { getEmptyImage } from "react-dnd-html5-backend";
import { findDOMNode } from "react-dom";
import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import flow from "lodash/flow";

/*
The Sortable component will allow drag and drop in its children. Children will be wrapped inside a <_SortableItem> component.
Props:
- handleDrop : handler to call when an item has been dropped
- itemIdKey : the key that can be used to identify items
- dragType : constant to identify dragged element
- dragTypeSuffix: ensures uniqueness if you have any Sortables with the same dragType in the same view.
- items : the list of items
- children
*/

class Sortable extends Component {

  dragTypeSuffixed() {
    const { dragType, dragTypeSuffix } = this.props;
    return dragType + dragTypeSuffix;
  }

  constructor(props) {
    super(props);
    this.SortableItem = flow(
      DropTarget(this.dragTypeSuffixed(), itemTarget, collectDropTarget),
      DragSource(this.dragTypeSuffixed(), itemSource, collectDragSource)
    )(_SortableItem);
    this.CustomDragLayer = DragLayer(collectDragLayer)(_CustomDragLayer);
    ["_moveItem", "_dropItem", "_stateUpdateHash", "_scheduleUpdate", "_drawFrame", "_rollbackFromProps"].forEach(fn => {
      this[fn] = this[fn].bind(this);
    });

    this.state = { itemsById: {}, itemsByIndex: [], boundingRect: undefined };
    this._scheduleUpdate(this._stateUpdateHash());
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.requestedFrame);
  }

  componentDidUpdate(prevProps) {
    const node = findDOMNode(this);
    const { boundingRect } = this.state;
    if (node) {
      const currentBoundingRect = node.getBoundingClientRect();
      if (!boundingRect) {
        this.setState({
          boundingRect: currentBoundingRect
        });
        return;
      }

      if (currentBoundingRect.right != boundingRect.right ||
          currentBoundingRect.left != boundingRect.left ||
          currentBoundingRect.top != boundingRect.top ||
          currentBoundingRect.bottom != boundingRect.bottom) {
        this.setState({
          boundingRect: currentBoundingRect
        });
      }
    }
    if (prevProps !== this.props) {
      this._scheduleUpdate(this._stateUpdateHash(this.props));
    }
  }

  _stateUpdateHash(props = this.props) {
    const { items, children, itemIdKey, itemIndexKey } = props;
    if (items.length == 0 && !children) return;
    if (items.length != children.length) {
      throw new Error("Sortable: items and children props must have the same length");
    }
    const itemsById = {};
    const itemsByIndex = [];
    Children.forEach(children, (child, index) => {
      const item = items[index];
      if (!item) return;

      const fullItem = { id: item[itemIdKey] || item[itemIndexKey], item, child };
      itemsById[fullItem.id] = fullItem;
      itemsByIndex[index] = fullItem;
    });
    return {
      itemsById: {
        $set: itemsById
      },
      itemsByIndex: {
        $set: itemsByIndex
      }
    };
  }

  _scheduleUpdate(updateFn) {
    this.pendingUpdateFn = updateFn;

    if (!this.requestedFrame) {
      this.requestedFrame = requestAnimationFrame(this._drawFrame);
    }
  }

  _drawFrame() {
    const nextState = update(this.state, this.pendingUpdateFn);
    this.setState(nextState);

    this.pendingUpdateFn = null;
    this.requestedFrame = null;
  }


  _moveItem(id, afterId) {
    if (id === afterId) return;

    const { itemsById, itemsByIndex } = this.state;

    const item = itemsById[id];
    const afterItem = itemsById[afterId];

    const itemIndex = itemsByIndex.indexOf(item);
    const afterIndex = itemsByIndex.indexOf(afterItem);

    this._scheduleUpdate({
      itemsByIndex: {
        $splice: [
          [itemIndex, 1],
          [afterIndex, 0, item]
        ]
      }
    });
  }

  _rollbackFromProps() {
    this._scheduleUpdate(this._stateUpdateHash());
  }

  _dropItem(id) {
    const { handleDrop, itemIndexKey } = this.props;
    const { itemsById, itemsByIndex } = this.state;

    //the state is up to date
    const item = itemsById[id];
    const itemIndex = itemsByIndex.indexOf(item);

    const previousItemId = (itemIndex > 0) ? itemsByIndex[itemIndex - 1].id : undefined;
    const nextItemId = (itemIndex < itemsByIndex.length - 1) ? itemsByIndex[itemIndex + 1].id : undefined;

    // estimate the next index
    let estimatedIndex = undefined;

    const previousItem = itemsById[previousItemId];
    const nextItem = itemsById[nextItemId];
    if (nextItem && previousItem) {
      estimatedIndex = previousItem.item[itemIndexKey] + ((nextItem.item[itemIndexKey] - previousItem.item[itemIndexKey]) / 2);
    } else if (previousItem) {
      estimatedIndex = previousItem.item[itemIndexKey] + 1000; //last item
    } else if (nextItem) {
      estimatedIndex = (nextItem.item[itemIndexKey] == 0 ? -1 : (nextItem.item[itemIndexKey] / 2));
    }

    handleDrop(previousItemId, id, nextItemId, estimatedIndex, itemsByIndex);
  }

  render() {
    const { handlePosition, mode, handleColumnIndex, fullyDraggable, onClick, children, additionalClasses, horizontalDragging, wrapperClass } = this.props;
    const { itemsByIndex, boundingRect } = this.state;
    const { SortableItem, CustomDragLayer } = this;

    const childrenByKey = {};
    Children.forEach(children, child => {
      if (!child || !child.key) return;

      childrenByKey[child.key] = child;
    });

    const sortableChildren = itemsByIndex.map(element => {
      if (!element) return null;

      const { child, item, id } = element;

      return (
        <SortableItem
          key={id}
          item={item}
          id={id}
          moveItem={this._moveItem}
          dropItem={this._dropItem}
          onClick={onClick}
          rollbackFromProps={this._rollbackFromProps}
          handlePosition={handlePosition}
          mode={mode}
          handleColumnIndex={handleColumnIndex}
          fullyDraggable={fullyDraggable}
          additionalClasses={additionalClasses}
        >
          { childrenByKey[child && child.key] }
        </SortableItem>
      );
    });

    if (mode === "table") {
      return (
        <tbody className={`${wrapperClass}`}>
          { sortableChildren }
          <CustomDragLayer parentBoundingRect={boundingRect} dragType={this.dragTypeSuffixed()} mode={mode} fullyDraggable={fullyDraggable} horizontalDragging={horizontalDragging}/>
        </tbody>
      );
    }

    return (
      <div className={`${wrapperClass}`}>
        { sortableChildren }
        <CustomDragLayer parentBoundingRect={boundingRect} dragType={this.dragTypeSuffixed()} mode={mode} fullyDraggable={fullyDraggable} horizontalDragging={horizontalDragging} additionalClasses={additionalClasses}/>
      </div>
    );
  }

}

export default DragDropContext(HTML5Backend)(Sortable);


// render a handle at given position (top, left, right). This used in SortableItem and CustompDragLayer
const handle = (
  <span className="grap-icon" style={{ cursor: "move" }}>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='15'>
      <circle cx='3' cy='4.3' r='1.8' fill='#777'/>
      <circle cx='8' cy='4.3' r='1.8' fill='#777'/>
      <circle cx='13' cy='4.3' r='1.8' fill='#777'/>
      <circle cx='3' cy='10' r='1.8' fill='#777'/>
      <circle cx='8' cy='10' r='1.8' fill='#777'/>
      <circle cx='13' cy='10' r='1.8' fill='#777'/>
    </svg>
  </span>
);

function renderHandleAndChildren(position, handle, children, opacity, withMovingStyle = false) {
  let childStyle = withMovingStyle ? {
    boxShadow: "0px 10px 17px #DDDDDD, 0px -10px 17px #DDDDDD",
    opacity: 0.8,
    backgroundColor: "rgba(255,255,255,0.2)"
  } : {};

  if (position === "left") {
    childStyle = Object.assign({}, childStyle, { width: "97%" });
    return (
      <div className="draggable-item">
        <div style={{ opacity, width: "3%", backgroundColor: "transparent" }} className="float-start">
          { handle }
        </div>
        <div className="float-start" style={ childStyle }>
          { children }
        </div>
        <div className="clearfix" />
      </div>
    );
  }
  return (
    <div className="draggable-item">
      <div style={ { opacity, textAlign: "center", backgroundColor: "transparent" } }>
        { handle }
      </div>
      <div style={ childStyle }>
        { children }
      </div>
      <div className="clearfix" />
    </div>
  );
}

function renderChildren(children, opacity, withMovingStyle = false) {
  let childStyle = withMovingStyle ? {
    cursor: "move"
  } : {};
  const enrichedChildren = !withMovingStyle ? children : cloneElement(children, { style: Object.assign({}, children.props.style, childStyle) });

  return (
    <div>
      { enrichedChildren }
      <div className="clearfix" />
    </div>
  );
}

function renderTrs(children, handle, handleIndex) {
  const tdsEnriched = children.props.children.map((td, index) => {
    if (!isEmpty(td)) {
      if (index === handleIndex) {
        return cloneElement(td, {
          children: [td.props.children, handle]
        });
      } else {
        return td;
      }
    } else {
      return [];
    }
  });
  return cloneElement(children, { children: tdsEnriched });
}

/*
SortableItem component represents an item that may be dragged and dropped within a Sortable component. It should not be used directly
*/

const itemSource = {
  beginDrag(props) {
    const { id, children, handlePosition } = props;
    return {
      id,
      children,
      handlePosition
    };
  },

  endDrag(props, monitor) {
    const didDrop = monitor.didDrop();
    if (!didDrop) {
      props.rollbackFromProps();
    }
  }
};

// scrolling handler
let frame = null;

function scrollBy(offset) {
  return () => {
    window.scrollBy(0, offset);
    frame = null;
  };
}

function scheduleScroll(offset) {
  if (!frame) {
    frame = window.requestAnimationFrame(scrollBy(offset));
  }
}

const itemTarget = {
  hover(props, monitor, component) {
    //auto scroll handling

    const { top, bottom } = findDOMNode(component).parentNode.getBoundingClientRect();
    const scrollMargin = 40;
    const mousePositionY = monitor.getClientOffset().y;

    const viewportHeight = $(window).height();

    const scrollAreaY = Math.max(140, viewportHeight * 0.1); // scroll when mouse reach top/bottom 10% area
    let speed;

    if (top < scrollMargin && mousePositionY < scrollAreaY) {
      speed = -1 + mousePositionY / scrollAreaY;
    } else if (bottom - scrollMargin > viewportHeight && mousePositionY > viewportHeight - scrollAreaY) {
      speed = 1 - (viewportHeight - mousePositionY) / scrollAreaY;
    }

    if (speed) {
      scheduleScroll(Math.round(speed * 100));
    }

    // actual dnd logic
    if (monitor.isOver()) {
      return;
    }
    const { id, moveItem } = props;
    const draggedId = monitor.getItem().id;

    moveItem(draggedId, id);
  },

  drop(props, monitor) {
    if (monitor.didDrop()) {
      // If you want, you can check whether some nested
      // target already handled drop
      return;
    }
    frame = null;
    const { id } = monitor.getItem();
    props.dropItem(id);
  }

};

function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

function collectDropTarget(connect) {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

class _SortableItem extends Component {

  constructor(props) {
    super(props);
    this._onMouseOver = this._onMouseOver.bind(this);
    this._onMouseOut = this._onMouseOut.bind(this);
    this._onClick = this._onClick.bind(this);
    this.state = { hover: false };
  }

  componentDidMount() {
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    this.props.connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true
    });
  }

  _onMouseOver() {
    this._changeOverState(true);
  }

  _onMouseOut() {
    this._changeOverState(false);
  }

  _onClick(item) {
    return (e) => {
      const { onClick } = this.props;
      if (onClick) {
        onClick(item)(e);
      }
    };
  }

  _changeOverState(newValue) {
    const hover = this.state;
    if (hover == newValue) {
      return;
    }
    this.setState({ hover: newValue });
  }

  renderClassic() {
    const { isDragging, connectDragSource, connectDropTarget, handlePosition, children, fullyDraggable, item, additionalClasses } = this.props;
    const { hover } = this.state;

    if (fullyDraggable) {
      return connectDropTarget(
        <div style={ { opacity: isDragging ? 0 : 1 } } onMouseOver={ this._onMouseOver } onMouseOut={ this._onMouseOut } onClick={ this._onClick(item)} className={additionalClasses}>
          { renderChildren(connectDragSource(children), children, hover ? 1 : 0) }
        </div>
      );
    }

    return connectDropTarget(
      <div style={ { opacity: isDragging ? 0 : 1 } } onMouseOver={ this._onMouseOver } onMouseOut={ this._onMouseOut } onClick={ this._onClick(item) } >
        { renderHandleAndChildren(handlePosition, connectDragSource(handle), children, hover ? 1 : 0) }
      </div>
    );
  }

  renderForTable() {
    const { isDragging, connectDragSource, connectDropTarget, children, handleColumnIndex } = this.props;
    const childrenNodes = renderTrs(children, connectDragSource(handle), handleColumnIndex);
    const childrenNodesEnriched = cloneElement(childrenNodes, {
      style: { opacity: isDragging ? 0 : 1 },
      onMouseOver: this._onMouseOver,
      onMouseOut: this._onMouseOut
    });
    return connectDropTarget(childrenNodesEnriched);
  }

  render() {
    const { connectDropTarget, children, item, mode } = this.props;
    if (!children) return null;
    if (item.nonSortable) {
      if (item.isNotDropTarget) {
      // wrapping children into a div, because react dnd do not allow ref to connected children
        return <div>{ children }</div>;
      }
      return connectDropTarget(<div>{ children }</div>);
    }
    return mode === "table" ? this.renderForTable() : this.renderClassic();
  }
}

/*
Custom DragLayer
*/

function getLayerStyle(props, state) {
  const { parentBoundingRect, nodeBoundingRect } = state;
  const { horizontalDragging } = props;
  if (!parentBoundingRect) {
    return {};
  }
  const { top, width } = parentBoundingRect;
  const { height } = nodeBoundingRect;
  return {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: "auto",
    right: "auto",
    top: top,
    width: horizontalDragging ? "" : width,
    height: height
  };
}

function getItemPositionningStyle(props, state) {
  const { currentOffset, horizontalDragging } = props;
  const { nodeBoundingRect, parentBoundingRect } = state;

  if (!currentOffset || !nodeBoundingRect || !parentBoundingRect) {
    return {
      display: "none"
    };
  }

  if (horizontalDragging) {
    return horizontalDraggingPositionningStyle(props, state);
  } else {
    return verticalDraggingPositionningStyle(props, state);
  }
}

function horizontalDraggingPositionningStyle(props, state) {
  const { currentOffset } = props;
  const { nodeBoundingRect, parentBoundingRect } = state;
  const { left, right } = parentBoundingRect;
  const { width } = nodeBoundingRect;
  let x = currentOffset.x - left - (width / 2);
  if (x < 0) {
    x = 0;
  }

  const rightLimit = right - left + (width / 2);
  if (x > rightLimit) {
    x = rightLimit;
  }

  const transform = `translateX(${x}px)`;
  return {
    transform: transform,
    WebkitTransform: transform
  };
}

function verticalDraggingPositionningStyle(props, state) {
  const { currentOffset } = props;
  const { nodeBoundingRect, parentBoundingRect } = state;
  const { top, bottom } = parentBoundingRect;
  const { height } = nodeBoundingRect;
  let y = currentOffset.y - top - (height / 2);
  if (y < 0) {
    y = 0;
  }

  const bottomLimit = bottom - top + (height / 2);
  if (y > bottomLimit) {
    y = bottomLimit;
  }

  const transform = `translateY(${y}px)`;
  return {
    transform: transform,
    WebkitTransform: transform
  };
}

function collectDragLayer(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging()
  };
}

class _CustomDragLayer extends Component {
  constructor(props) {
    super(props);
    this.state = { nodeBoundingRect: undefined, parentBoundingRect: undefined };
  }

  componentDidUpdate() {
    const node = findDOMNode(this);
    if (node) {
      const nodeBoundingRect = node.children[0].getBoundingClientRect();
      const { parentBoundingRect } = this.props;
      if (parentBoundingRect && this.state.nodeBoundingRect != nodeBoundingRect && this.state.parentBoundingRect != parentBoundingRect) {
        this.setState({ nodeBoundingRect, parentBoundingRect });
      }
    }
  }

  renderClassic() {
    const { item, itemType, isDragging, dragType, fullyDraggable, additionalClasses, horizontalDragging } = this.props;
    if (dragType != itemType || !isDragging) {
      return null;
    }
    if (fullyDraggable) {
      const classes = horizontalDragging ? additionalClasses : null;
      return (
        <div className={classes} style={getLayerStyle(this.props, this.state)}>
          <div style={getItemPositionningStyle(this.props, this.state)}>
            { renderChildren(item.children, 1, true) }
          </div>
        </div>
      );
    }

    return (
      <div style={getLayerStyle(this.props, this.state)}>
        <div style={getItemPositionningStyle(this.props, this.state)}>
          { renderHandleAndChildren(item.handlePosition, handle, item.children, 1, true) }
        </div>
      </div>
    );
  }

  renderForTable() {
    const { item, itemType, isDragging, dragType, handleColumnIndex } = this.props;
    if (dragType != itemType || !isDragging) {
      return null;
    }
    const component = cloneElement(item.children, {
      style: merge(getItemPositionningStyle(this.props, this.state), getLayerStyle(this.props, this.state))
    });
    return renderTrs(component, handle, handleColumnIndex);
  }

  render() {
    const { mode } = this.props;
    return mode === "table" ? this.renderForTable() : this.renderClassic();
  }
}

Sortable.propTypes = {
  mode: PropTypes.string,
  items: PropTypes.array.isRequired,
  handleDrop: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  dragType: PropTypes.string.isRequired,
  itemIdKey: PropTypes.string.isRequired,
  handleColumnIndex: PropTypes.number,
  fullyDraggable: PropTypes.bool
};

Sortable.defaultProps = {
  mode: "form-builder",
  items: [],
  handleColumnIndex: 0,
  fullyDraggable: false
};
