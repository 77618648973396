import { ChangeEvent } from "react";
import { LeadQualificationField } from "../../types/LeadQualificationField";
import { DragTypes } from "../../constants/Constants";
import Sortable from "../Sortable.react";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`front_office.react.lead_qualification_fields.${key}`, options);
};

interface Props {
  leadQualificationField: LeadQualificationField;
  onChange(leadQualificationField: LeadQualificationField): void;
}

const LeadQualificationFieldEdit: React.FC<Props> = ({ leadQualificationField, onChange }) => {
  const onChangeLabel = (e: ChangeEvent<HTMLInputElement>): void => {
    leadQualificationField.label = e.target.value;
    onChange(leadQualificationField);
  };

  const currentPossibleValues = leadQualificationField.possible_values?.filter(val => !!val) || [];

  const onAddPossibleValue = (): void => {
    const newPossibleValues = [...currentPossibleValues, i18n("new_option")];
    onChange({ ...leadQualificationField, possible_values: newPossibleValues });
  };

  const onChangePossibleValue = (index: number): ((e: ChangeEvent<HTMLInputElement>) => void) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      currentPossibleValues[index] = e.target.value;
      onChange({ ...leadQualificationField, possible_values: currentPossibleValues });
    };
  };

  const onRemovePossibleValue = (index: number): (() => void) => {
    return (): void => {
      const newPossibleValues = [...currentPossibleValues];
      delete newPossibleValues[index];
      onChange({ ...leadQualificationField, possible_values: newPossibleValues });
    };
  };

  const renderAddPossibleValueButton = (): JSX.Element => {
    return <a href="#" onClick={onAddPossibleValue}>
      <i className="fa fa-plus" aria-hidden="true"></i> { i18n("add_option") }
    </a>;
  };

  const renderRemovePossibleValueButton = (index: number): JSX.Element => {
    return <button className="btn btn-secondary" onClick={onRemovePossibleValue(index)}>
      <i className="fa-regular fa-trash-can" aria-hidden="true"></i>
    </button>;
  };

  const renderPossibleValueOptions = (): JSX.Element[] => {
    return currentPossibleValues.map((possibleValue, index) => {
      return <div className="row" key={index}>
        <div className="col-md-8 col-10">
          <input type="text" className="form-control" value={possibleValue} onChange={onChangePossibleValue(index)} />
        </div>
        <div className="col-2">
          { renderRemovePossibleValueButton(index) }
        </div>
      </div>;
    });
  };

  const handlePossibleValueDrop = (_a: any, _b: any, _c: any, _d: any, sortedItems: any): void => {
    const newPossibleValues = sortedItems.map(item => currentPossibleValues[item.id]);
    onChange({ ...leadQualificationField, possible_values: newPossibleValues });
  };

  const sortablePossibleValues = currentPossibleValues.map((value, index) => {
    return { rank: index };
  });

  const renderPossibleValues = (): JSX.Element => {
    return <div className="mb-3">
      <label className="form-label">{ i18n("possible_values") }</label>
      <Sortable itemIdKey="rank"
        itemIndexKey="rank"
        dragType={DragTypes.LEAD_QUALIFICATION_FIELD_POSSIBLE_VALUES}
        items={sortablePossibleValues}
        handleDrop={handlePossibleValueDrop}
        handlePosition="left"
      >
        { renderPossibleValueOptions() }
      </Sortable>
      { renderAddPossibleValueButton() }
    </div>;
  };

  const renderRatingInput = (): JSX.Element => {
    return <div className="rating d-flex align-items-center">
      <i className="fa-solid fa-star" aria-hidden="true"></i>
      <i className="fa-solid fa-star" aria-hidden="true"></i>
      <i className="fa-solid fa-star" aria-hidden="true"></i>
      <i className="fa-solid fa-star" aria-hidden="true"></i>
      <i className="fa-solid fa-star" aria-hidden="true"></i>
    </div>;
  };

  const renderQuestion = (): JSX.Element => {
    if (leadQualificationField.type === "rating") return renderRatingInput();
    if (leadQualificationField.type === "value_list") return renderPossibleValues();
  };

  return <div className="card focused">
    <div className="card-header">
      <h4 className="card-title">{leadQualificationField.label}</h4>
      <div className="form-text">{ i18n(`type_${leadQualificationField.type}`) }</div>
    </div>
    <div className="card-body">
      <div className="mb-3">
        <label className="form-label">{ i18n("label") }</label>
        <input type="text" className="form-control" value={leadQualificationField.label} onChange={onChangeLabel} />
      </div>
      { renderQuestion() }
    </div>
  </div>;
};

export default LeadQualificationFieldEdit;
